import React, { forwardRef, Text } from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

import MaterialTable from '@material-table/core';
import axios from 'axios';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Checkbox, FormControlLabel, FormGroup, Switch } from '@material-ui/core';

import MenuButton from '../components/MenuButton';
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


class Contratti extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            estimates: [],
            menuOpen: null,
            suspended: false,
            backupEstimates: [],
            visualizingArchived: false
        }
    }
    
    componentDidMount = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/estimates?status=1', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        })
        
        console.log(res.data);

        for(let i in res.data){
            const estimate = res.data[i];
            // let orderMovements = await axios.get('https://back.rauccihomedesign.it/api/v1/order-movements/' + estimate.estimate_id, {
            //     headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            // });

            const residuo = estimate.price - estimate.sommaAnticipi;

            res.data[i].residuo = residuo;

        }
        this.setState({ estimates: res.data.sort((a,b ) => b.id - a.id), backupEstimates: res.data});
    }

    onlySuspended = async () => {

        await this.setState({suspended: !this.state.suspended});
        if(this.state.suspended){

            let estimates = this.state.estimates;
            estimates = estimates.filter(x => x.residuo > 10);
            this.setState({estimates: estimates});
        }else{
            const estimates = this.state.backupEstimates;
            this.setState({estimates: estimates});
        }
        
    }


    getArchivedContracts = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/estimates?status=2', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        })
        
        console.log(res.data);
        this.setState({visualizingArchived: true});
        for(let i in res.data){
            const estimate = res.data[i];
            // let orderMovements = await axios.get('https://back.rauccihomedesign.it/api/v1/order-movements/' + estimate.estimate_id, {
            //     headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            // });

            const sommaAnticipi = estimate.orderMovements.filter(x => !x.isExpense).map(x => x.price).reduce((acc, curr, array) => acc + curr, 0);
            const residuo = estimate.price - sommaAnticipi;

            res.data[i].residuo = residuo;

        }
        this.setState({ estimates: res.data.sort((a,b ) => b.id - a.id), backupEstimates: res.data});
    }

   
    getNormalContracts = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/estimates?status=1', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        })
        
        this.setState({visualizingArchived: false});
        for(let i in res.data){
            const estimate = res.data[i];

            const sommaAnticipi = estimate.orderMovements.filter(x => !x.isExpense).map(x => x.price).reduce((acc, curr, array) => acc + curr, 0);
            const residuo = estimate.price - sommaAnticipi;

            res.data[i].residuo = residuo;

        }
        console.log(res.data);
        this.setState({ estimates: res.data.sort((a,b ) => b.id - a.id), backupEstimates: res.data});
    }

    deleteContract = async(id) => {
        if(window.confirm('Sei sicuro di voler cancellare il contratto?') == true){
            const res = await axios.delete('https://back.rauccihomedesign.it/api/v1/estimates/' + id, {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });
            if(res.status === 200){
                this.setState({
                    estimates: this.state.estimates.filter(x => x.estimate_id !== id)
                })
            }
        }
       
    }

    archiveContract = async (id) => {
        if(window.confirm('Sei sicuro di voler archiviare il contratto?') == true){
            const estimate = await axios.get('https://back.rauccihomedesign.it/api/v1/estimates/' + id +'?status=' + 0,
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
            );
            const res = await axios.put('https://back.rauccihomedesign.it/api/v1/estimates/' + id,
            {
                ...estimate.data,
                store:  estimate.data.shop,
                status: 2
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });
            if(res.status === 200){
                this.setState({
                    estimates: this.state.estimates.filter(x => x.estimate_id !== id)
                })
            }
        }
       
    }

    goToProduction = async(e, id) => {
        const res = await axios.put(`http://162.19.25.192/api/v1/production/${id}`, {
            production: e
        }, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        });
        console.log(res);
    }
    render(){
        return (
            <div>
               
                <h1>Contratti</h1>
                <strong>Numero contratti: {this.state.estimates.length}</strong>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.suspended}
                                onChange={() => this.onlySuspended()}
                                name="suspended"
                                color="primary"
                            />
                        }
                        label="Mostra solo sospesi"
                    />
                </FormGroup>
                <Button variant="contained" style={{marginBottom: 24, marginTop: 24}} onClick={() => !this.state.visualizingArchived ? this.getArchivedContracts() : this.getNormalContracts()}>{!this.state.visualizingArchived ? 'Mostra archiviati' : 'Mostra contratti' }</Button>
                {/**
                 * Try
                 */}
                <MaterialTable title="Elenco Contratti" icons={tableIcons} data={this.state.estimates}
                    options={{
                        paging:true,
                        pageSize:50,       // make initial page size
                        emptyRowsWhenPaging: false,   // To avoid of having empty rows
                        pageSizeOptions:[50,100,150,200],    // rows selection options,
                      }}
                      onSearchChange={(e) => console.log(e)}
                    
                    columns={[
                    {
                        title: 'Protocollo',
                        field: 'id',
                        render: data => <p>{data.progressive}/{data.year_created}</p>
                    },
                    {
                        title: 'Nome cliente',
                        field: 'name',
                        render: data => <p>{data.name} {data.surname}</p>,
                        customFilterAndSearch: (term, data) => (data.name.toLowerCase().includes(term.toLowerCase()) || data.surname.toLowerCase().includes(term.toLowerCase()))
                    },
                    {
                        title: 'Via',
                        field: 'address'
                    },
                    {
                        title: 'Città',
                        field: 'city'
                    },
                    {
                        title: 'E-mail',
                        field: 'email'
                    },
                    {
                        title: 'Telefono',
                        field: 'phone_number'
                    },
                    {
                        title: 'Totale contratto',
                        field: 'price',
                        render: data => <p>{(data.price / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</p>
                    },
                    {
                        title: 'Residuo',
                        field: 'residuo',
                        render: data => <p>{(data.residuo / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})} </p>
                    },
                    {
                        title: 'Data creazione',
                        field: 'created_at',
                        render: data => <p>{new Date(data.created_at).toLocaleDateString('it-IT')}</p>
                    },
                    {
                        title: 'In produzione',
                        field: 'production',
                        render: data => {console.log(data.production); return(<p><Switch value={data.production} defaultChecked={data.production} onChange={(e) => this.goToProduction(e.target.checked, data.estimate_id)}/></p>);}
                    },
                    {
                        title: 'Azioni',
                        field: 'updated_at',
                        render: data => 
                            <div>
                                <MenuButton items={[{title: 'Modifica', href: "/nuovo-contratto"}, {title: 'Merce da ordinare', href: "/da-ordinare"}, {title: "Merce ordinata", href: "/ordinato"}, {title: 'In Uscita', href: '/in-uscita-contratto'}, {title: 'Movimenti', href: "/movimenti"}, {title: 'Stampa', href: "https://back.rauccihomedesign.it/contract-pdf/" + data.estimate_id, link: true}]} data={data.estimate_id} dataPass={data}></MenuButton>
                                <Button onClick={() => this.archiveContract(data.estimate_id)}>Archivia</Button>
                                <Button onClick={() => this.deleteContract(data.estimate_id)}>Elimina</Button>
                            </div>
                    }
                ]}></MaterialTable>
            </div>
        )
    }
}

export default withRouter(Contratti);